import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from "prop-types";

import placeholder from '../../images/placeholder.jpg';

import './scss/index.scss';

const PostCard = ({ post }) => {
    let featuredImage;

    const postContent = post.node.content.split(`<!--more-->`)

    if (post.node.acf && post.node.acf.icon) {
        featuredImage = <div className="card-icon"><img src={post.node.acf.icon.localFile.publicURL} alt="Placeholder" /></div>
    } else if (post.node.featured_media)
        featuredImage = <div className="card-image"><Img fluid={post.node.featured_media.localFile.childImageSharp.fluid} alt="" /></div>
    else {
        featuredImage = <div className="card-image placeholder"><img src={placeholder} alt="Placeholder" /></div>
    }

    return (
        <article className="col-md-6 pr-0 pl-0">
            <div className="card p-2">
                <div className="card-container">
                    <div className="card-title mt-lg-4 mb-lg-3 d-flex flex-column justify-content-center">
                        <h4 className="fw-light">{post.node.title}</h4>
                    </div>
                    {featuredImage}
                    <div className="text-center mt-4 mb-lg-5">{ ReactHtmlParser(postContent[0]) }</div>
                </div>
                <Link className="btn btn-outline-secondary btn-sm mb-5" to={post.node.slug || post.node.link}>Więcej</Link>
            </div>
        </article>
    )
}

PostCard.propTypes = {
    post: PropTypes.object.isRequired,
}

export default PostCard